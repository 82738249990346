import { useState } from "react";

import './../css/view.css';

import { TbBarrierBlock } from "react-icons/tb";
import { TbAlertTriangle } from "react-icons/tb";

import { getBackgroundColor, getColor } from "../../Logic/cell";
import { ROAD_BLOCKS, ROUGH_PATCH } from "../../Conf/conf";
import ContextMenu from "./ContextMenu";

import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export default function ViewMatrixWithVirtualization({ matrix, useRoughPatches, path, replaceUniqueCell, changeCell, addWaypoint}) {
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [currentPos, setCurrentPos] = useState([]);
    const [isMenuVisible, setIsMenuVisible] = useState(false);


    const handleClick = (e, row, col, type) => {
        e.preventDefault();
        setCurrentPos([row,col,type]);
        const { pageX, pageY } = e;
        setMenuPosition({ x: pageX, y: pageY });
        setIsMenuVisible(true);
    };

    const totalRows = matrix.length;
    return (
        <AutoSizer>
            {() => (
                <div>
                    <FixedSizeList
                        height={window.innerHeight/1.5}
                        width={window.innerWidth/1.05}
                        itemCount={totalRows}
                        itemSize={36}
                        className="view-background-color"
                        style={{ marginLeft:'2rem', marginRight:'2rem'}}
                    >
                        {({ index, style }) => (
                            <div style={{ ...style}} className="view-container">
                                {matrix[index].map((cell, cellIndex) => (
                                    <span key={cell + "-" + cellIndex} className='cell-container cell'
                                        onClick={(e) => handleClick(e, index, cellIndex, cell)}
                                        style={{ color: getColor(cell), backgroundColor: getBackgroundColor(cell, index, cellIndex, useRoughPatches, path)}}
                                    >
                                        <strong className="cell-content">
                                            {cell === ROAD_BLOCKS && <span className="cell-icon cell-icon-block"><TbBarrierBlock/></span>}
                                            {cell === ROUGH_PATCH && 
                                                <>{
                                                    useRoughPatches 
                                                    ? <span className="cell-icon cell-icon-warning-on"><TbAlertTriangle/></span>
                                                    : <span className="cell-icon cell-icon-warning-off"><TbAlertTriangle/></span>
                                                }</>
                                            }
                                            {cell !== ROAD_BLOCKS && cell !== ROUGH_PATCH && <>{cell}</>}
                                        </strong>
                                    </span>
                                ))}
                            </div>
                        )}
                    </FixedSizeList>
                    {isMenuVisible && (
                        <ContextMenu x={menuPosition.x} y={menuPosition.y} onClose={() => setIsMenuVisible(false)}
                        replaceUniqueCell={replaceUniqueCell} currentPos={currentPos} changeCell={changeCell} addWaypoint={addWaypoint}/>)}
                </div>
            )}
        </AutoSizer>
 );
}
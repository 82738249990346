import React from 'react';
import ReactDOM from 'react-dom/client';

import RoutesController from './RoutesController';
import './css/index.css';
import './css/fonts.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.Fragment>
        <RoutesController/>
    </React.Fragment>
);


export const EMPTY_SPACE = ' ';
export const DROP_ZONE_A = 'A';
export const DROP_ZONE_B = 'B';
export const ROAD_BLOCKS = 'w';
export const ROUGH_PATCH = 'rp';

export const CELL_SIZE = "32px";
export const CELL_SIZE_NUMBER = 32;

export const SQUARE_AREA_DEFAULT = 250;
export const N_DEFAULT = 10;
export const M_DEFAULT = 10;
export const BLOCKS_DEFAULT = 0;
export const ROUGHS_DEFAULT = 0;

export const N_MIN_FOR_WINDOW_RENDERING = 50;
export const N_MAX = 1000;
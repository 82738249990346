import findShortestPath from "./bfsAlgorithm";

export const calculateShortestPath = (matrix, start, end, midPoints, useRoughPatches, useDiagonals) => {
    if(midPoints.length > 0){
        let newPath = [];
        let newMidPoints = [start, ...midPoints, end];
        for(let i=1; i<newMidPoints.length; i++){
            newPath.push(...findShortestPath(matrix, useRoughPatches, useDiagonals, newMidPoints[i-1], newMidPoints[i]));
        }
        return removeDuplicateNeighbors(newPath);
    } else {
        return findShortestPath(matrix, useRoughPatches, useDiagonals, start, end);
    }
}

export const calculateDist = (path, useDiagonals, squareLength) => {
    if(path !== null){
        if(!useDiagonals){
            return (path.length-1)*squareLength;
        } else {
            let temp_dist = 0;
            for(let i=1; i<path.length; i++){
                if(path[i].col === path[i-1].col || path[i].row === path[i-1].row){
                    temp_dist += squareLength;
                } else {
                    temp_dist += Math.sqrt(squareLength*squareLength + squareLength*squareLength);
                }
            }
            return temp_dist;
        }
    }
}

export const checkIfOnPath = (row,col,path) => {
    if(path !== null){
        for(let i=0; i<path.length; i++){
            if(path[i].row === row && path[i].col === col){
                return true;
            }
        }
    }
    return false;
}

function removeDuplicateNeighbors(inputArray) {
    const newArray = [];
    let prevElement = null;
  
    for (const element of inputArray) {
        if (
            prevElement === null ||
            element.row !== prevElement.row ||
            element.col !== prevElement.col
        ) {
            newArray.push(element);
        }
        prevElement = element;
    }
  
    return newArray;
}
import { DROP_ZONE_A, DROP_ZONE_B, EMPTY_SPACE, ROAD_BLOCKS, ROUGH_PATCH } from "../../Conf/conf";

import { TbX } from "react-icons/tb";

const ContextMenu = ({ currentPos, x, y, onClose, replaceUniqueCell, changeCell, addWaypoint}) => {
    const getCellTitle = (type) => {
        switch(type){
            case DROP_ZONE_A:
                return 'Starting Position';
            case DROP_ZONE_B:
                return 'Final Position';
            case EMPTY_SPACE:
                return 'Empty Space';
            case ROAD_BLOCKS:
                return 'Roadblock';
            case ROUGH_PATCH:
                return 'Rough Patch';
            default:
                return 'Waypoint ' + type;
        }
    }

    return (
        <div style={{top: y, left: x}} className="select-menu-container" onMouseLeave={onClose}>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <strong>{getCellTitle(currentPos[2])}</strong>
                <TbX onClick={onClose} className='select-menu-close'/>
            </div>

            {typeof currentPos[2] === 'number' && 
                <div className='select-menu-option' onClick={() => {addWaypoint(currentPos[0], currentPos[1], 'remove', currentPos[2]); onClose();}}>
                    Remove Waypoint
                </div>
            }
            {(currentPos[2] === ROAD_BLOCKS || currentPos[2] === ROUGH_PATCH || currentPos[2] === EMPTY_SPACE ) &&
                <>
                    <div className='select-menu-option' onClick={() => {replaceUniqueCell(DROP_ZONE_A,currentPos[0], currentPos[1]); onClose();}}>Mark as <strong>Start</strong></div>
                    <div className='select-menu-option' onClick={() => {replaceUniqueCell(DROP_ZONE_B,currentPos[0], currentPos[1]); onClose();}}>Mark as <strong>End</strong></div>
                    <div className='select-menu-option' onClick={() => {changeCell(ROAD_BLOCKS,currentPos[0], currentPos[1]); onClose();}}>Mark as <strong>Roadblock</strong></div>
                    <div className='select-menu-option' onClick={() => {changeCell(ROUGH_PATCH,currentPos[0], currentPos[1]); onClose();}}>Mark as <strong>Rough Patch</strong></div>
                    <div className='select-menu-option' onClick={() => {changeCell(EMPTY_SPACE,currentPos[0], currentPos[1]); onClose();}}>Mark as <strong>Empty</strong></div>
                    <div className='select-menu-option' onClick={() => {addWaypoint(currentPos[0], currentPos[1], 'add'); onClose();}}>Mark as <strong>Waypoint</strong></div>
                </>
            }
        </div>
    );
};

export default ContextMenu;
import './../css/input.css';
import './../css/tooltip.css';
import './../css/options.css';

import { BsFillInfoCircleFill } from "react-icons/bs";
import { N_MAX, N_MIN_FOR_WINDOW_RENDERING } from '../../Conf/conf';

export default function GenerateMatrixOptions({n, m, blocksQuantity, roughsQuantity, squareArea, getNewMatrix}){
    const onChangeDimensions = (type, e) => {
        if(e.target.value > 1 && e.target.value <= N_MAX){
            switch(type){
                case 'n':
                    n[1](parseInt(e.target.value));
                    break;
                case 'm':
                    m[1](parseInt(e.target.value));
                    break; 
                default: break;
            }
        }
    }

    const constrainNumber = (min, max, e, type) => {
        if((e.target.value <= max && e.target.value >= min)){
            switch(type){
                case 'blocks':
                    blocksQuantity[1](e.target.value);
                    break;
                case 'roughs':
                    roughsQuantity[1](e.target.value);
                    break;
                case 'square_area':
                    squareArea[1](e.target.value);
                    break;
                default: break;
            }
        } 
    }

    const OptionNumberInput = (name, min, max, value, tooltip, type) => {
        return <div className='tooltip-down'>
            <div className='option-input-container'>
                <strong>{name}</strong>
                <div className='option-input'>
                    <input style={{maxWidth:'4rem'}} type="number" value={value} onChange={(e) => constrainNumber(min,max,e,type)}/>
                </div>
            </div>
            <span className="tooltiptext-down">{tooltip}</span>
        </div>
    }

    const info = () => {
        return <div className='tooltip-left'>
            <div className='info-icon'>
                <BsFillInfoCircleFill/>
            </div>
            <span className="tooltiptext-left">After generating the matrix you can edit each cell individually</span>
        </div>;
    }

    const freeCells = (n[0]*m[0]) - 2;
    let maxBlocks = freeCells - roughsQuantity[0];
    let maxRoughs = freeCells - blocksQuantity[0];

    return <div className="options-container">
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between', gap:'1rem 1rem'}}>
            <div style={{display:'flex', flexWrap:'wrap', gap:'20px 50px', alignItems:'center'}}>
                
                <div className='tooltip-down'>
                    <div className='option-input-container'>
                        <strong>Matrix Size</strong>
                        <div className='option-input'>
                            <input style={{maxWidth:'3rem'}} placeholder="n" type="number" value={n[0]} onChange={(e) => onChangeDimensions('n', e)}/>
                            <input style={{maxWidth:'3rem'}} placeholder="m" type="number" value={m[0]} onChange={(e) => onChangeDimensions('m', e)}/>
                        </div>
                    </div>
                    <span className="tooltiptext-down">n(↓) by m(→). For values bigger than {N_MIN_FOR_WINDOW_RENDERING} it uses window rendering. Max value = {N_MAX}</span>
                </div>

                {OptionNumberInput("Square Area",0,999999,squareArea[0],"Cell area, can be altered to calculate the distance withouth generating a new matrix",'square_area')}
                {OptionNumberInput("Roadblocks",0,maxBlocks,blocksQuantity[0],"Untraversable cell, big values will cause slowdown",'blocks')}
                {OptionNumberInput("Rough Patches",0,maxRoughs,roughsQuantity[0],"Can be marked as an untraversable cell or behave as an empty cell, big values will cause slowdown",'roughs')}

                <div className='option-input-container options-button'>
                    <button id="generate-button" onClick={getNewMatrix}><strong>Generate Matrix</strong></button>
                </div>
            </div>
            {info()}
        </div>
    </div>
}
import React, { useState, useEffect } from "react"

import './css/matrix-controller.css';
import './../index.css';

import { DROP_ZONE_A, DROP_ZONE_B, EMPTY_SPACE, ROAD_BLOCKS, ROUGH_PATCH, ROUGHS_DEFAULT, SQUARE_AREA_DEFAULT, M_DEFAULT, N_DEFAULT, BLOCKS_DEFAULT, N_MIN_FOR_WINDOW_RENDERING } from "../Conf/conf";
import { findElementPosition, generateMatrix } from "../Logic/matrix";
import { calculateDist } from "../Logic/path";
import drawMatrix from "./ViewMatrix/DrawPathWithCanvas";
import ViewMatrix from "./ViewMatrix/ViewMatrix";
import ViewMatrixWithVirtualization from "./ViewMatrix/ViewMatrixWithVirtualization";
import Legend from "./ViewMatrix/Legend";
import GenerateMatrixOptions from "./Options/GenerateMatrixOptions";
import MatrixOptions from "./Options/MatrixOptions";

export default function MatrixController(){
    const [matrix, setMatrix] = useState([]);
    const [path, setPath] = useState(null);
    const [noPath, setNoPath] = useState(false); // When no path found
    const [waypoints, setWaypoints] = useState([]);
    const [dist, setDist] = useState(0);

    const [useRoughPatches, setUseRoughPatches] = useState(true);
    const [useDiagonals, setUseDiagonals] = useState(true);

    const [squareArea, setSquareArea] = useState(SQUARE_AREA_DEFAULT);
    const [nSize, setNSize] = useState(N_DEFAULT);
    const [mSize, setMSize] = useState(M_DEFAULT);
    const [lastBiggestSize, setLastBiggestSize] = useState(0); 
    const [blocksQuantity, setBlocksQuantity] = useState(BLOCKS_DEFAULT);
    const [roughsQuantity, setRoughsQuantity] = useState(ROUGHS_DEFAULT);

    useEffect(() => {
        if(path !== null){
            setDist(parseFloat(calculateDist(path, useDiagonals, Math.sqrt(squareArea))));
        }
    }, [path, squareArea, useDiagonals])

    const getNewMatrix = () => {
        cleanPath();
        setWaypoints([]);
        if(nSize > mSize) setLastBiggestSize(nSize); 
        else setLastBiggestSize(mSize);
        setMatrix(generateMatrix(nSize, mSize, DROP_ZONE_A, DROP_ZONE_B, EMPTY_SPACE, ROAD_BLOCKS, ROUGH_PATCH, blocksQuantity, roughsQuantity));
    }

    const cleanPath = () => {
        setPath(null); setNoPath(false); setDist(0);
    }

    const addWaypoint = (row, col, mode, element) => {
        if(mode === "add"){
            let newMidPoints = [...waypoints];
            let newPoint = waypoints.length+1;
            newMidPoints.push(newPoint);
            setWaypoints(newMidPoints);
            let newMatrix = [...matrix];
            newMatrix[row][col] = newPoint;
            setMatrix(newMatrix);
            cleanPath();
        } else if(mode === "remove"){
            let newMidPoints = [...waypoints];
            newMidPoints.pop();
            setWaypoints(newMidPoints);
            let target_pos = findElementPosition(matrix,element);
            changeCell(EMPTY_SPACE,target_pos.row,target_pos.col);
            for(let i=0; i<waypoints.length; i++){
                if(waypoints[i]>element){
                    let target_pos = findElementPosition(matrix,waypoints[i]);
                    changeCell(waypoints[i]-1,target_pos.row,target_pos.col);
                }
            }
        }
    }

    const replaceUniqueCell = (type, row, col) => {
        let newMatrix = [...matrix];
        for(let i=0; i<newMatrix.length; i++){
            for(let j=0; j<newMatrix[i].length; j++){
                if(newMatrix[i][j] === type){ newMatrix[i][j] = EMPTY_SPACE;}
            }
        }
        newMatrix[row][col] = type;
        setMatrix(newMatrix);
        cleanPath();
    }

    const changeCell = (type, row, col) => {
        let newMatrix = [...matrix];
        newMatrix[row][col] = type;
        setMatrix(newMatrix);
        cleanPath();
    }

    return <>
        <GenerateMatrixOptions n={[nSize, setNSize]} m={[mSize, setMSize]} 
            blocksQuantity={[blocksQuantity, setBlocksQuantity]} roughsQuantity={[roughsQuantity, setRoughsQuantity]} 
            squareArea={[squareArea, setSquareArea]} getNewMatrix={getNewMatrix}
        />

        <MatrixOptions matrix={matrix} useRoughPatches={useRoughPatches} setUseRoughPatches={setUseRoughPatches} 
            setPath={setPath} useDiagonals={useDiagonals} setUseDiagonals={setUseDiagonals}
            path={path} setNoPath={setNoPath} dist={dist} noPath={noPath} waypoints={waypoints}/>

        {/* VIEW MATRIX (WHEN SIZE IS BIG USE VIRTUALIZATION, ELSE USE CANVAS TO DISPLAY LINE) */}
        { matrix.length > 0 && nSize*mSize>=2 && 
            <div>
                <Legend/>
                { lastBiggestSize > N_MIN_FOR_WINDOW_RENDERING 
                    ? 
                        <ViewMatrixWithVirtualization matrix={matrix} useRoughPatches={useRoughPatches} path={path} 
                            replaceUniqueCell={replaceUniqueCell} changeCell={changeCell} addWaypoint={addWaypoint}
                        />
                    : 
                        <div className="matrix-body">
                            <div className="matrix-container">
                                <div className="matrix-box">
                                    <canvas id="matrix-canvas"></canvas>
                                    {drawMatrix(matrix, path)} 
                                </div>
                            </div>
                            <div className="matrix-front-box">{
                                <ViewMatrix matrix={matrix} useRoughPatches={useRoughPatches} path={path} 
                                    replaceUniqueCell={replaceUniqueCell} changeCell={changeCell} addWaypoint={addWaypoint}
                                />
                            } </div>
                        </div>
                }
            </div>
        }
    </>
}
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';

import LandingPage from './components/LandingPage/LandingPage';
import MoviesController from './components/Movies/MoviesController';
import { LanguageProvider } from './components/TodoList/components/language/LanguageContext';
import TodoListController from './components/TodoList/components/todoList/TodoListController';

import Challenges from './components/Challenges/Challenges';

// css
/*import './components/TodoList/css/global/colors.css';
import './components/TodoList/css/global/fonts.css';
import './components/TodoList/css/global/index.css';*/
import MatrixController from './components/Grids/Components/MatrixController';

export default function RoutesController(){
    useEffect(() => {
        document.title = "Luís Spínola"
    }, [])

    return (
        <BrowserRouter basename=''>
            <Routes>
                
                <Route path="/temp" element={<MatrixController/>}/>
                
            </Routes>
        </BrowserRouter>
    )
}

/*
<Route path="/" element={<LandingPage/>}/>
<Route path="/movies" element={<MoviesController/>}/>
                <Route path="/todolist" element={<LanguageProvider>
                    <TodoListController/>
                </LanguageProvider>}/>
                <Route path="/challenges" element={<Challenges/>}/>

*/
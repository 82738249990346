import { DROP_ZONE_A, DROP_ZONE_B } from "../../Conf/conf";
import { calculateShortestPath } from "../../Logic/path";

export default function MatrixOptions({matrix, useRoughPatches, setUseRoughPatches, setPath, useDiagonals, setUseDiagonals, path, dist, noPath, setNoPath, waypoints}){
    const calculatePath = () => {
        setNoPath(false);
        let newPath = calculateShortestPath(matrix, DROP_ZONE_A, DROP_ZONE_B, waypoints, useRoughPatches, useDiagonals);
        setPath(newPath);
        if(newPath === null) setNoPath(true);
    }
    
    return (
        <div className="matrix-options">
            { matrix.length > 0 && 
                <div style={{display:'flex', flexWrap:'wrap', alignContent:'center', alignItems:'center'}}>
                    <div style={{marginLeft:'1rem', display:'flex'}}>
                        Use Rough Patches
                        <div style={{display:'flex', flexWrap:'wrap', gap:'20px 20px'}}>
                            <input type="checkbox" checked={useRoughPatches} onChange={() => {setUseRoughPatches(!useRoughPatches); setPath(null)}}/>
                        </div>
                    </div>

                    <div style={{margin:'1rem', display:'flex'}}>
                        Use Diagonals
                        <div style={{display:'flex', flexWrap:'wrap', gap:'20px 20px'}}>
                            <input type="checkbox" checked={useDiagonals} onChange={() => {setUseDiagonals(!useDiagonals); setPath(null)}}/>
                        </div>
                    </div>
                    {
                        path !== null && 
                        <div style={{marginLeft:'1rem'}}>
                            <button style={{backgroundColor:'gray'}} onClick={() => setPath(null)}>Hide optimal path</button>
                        </div>
                    }
                    { 
                        <div style={{margin:'1rem'}}>
                            <button onClick={calculatePath}><strong>
                                Calculate Path
                            </strong></button>
                        </div>
                    }
                    
                    {noPath && <div style={{color:'red'}}><strong>No possible path found</strong></div>}
                    {path !== null && dist > 0 && <div style={{marginLeft:'2rem'}}>Distance: <strong>{dist.toFixed(2)}m</strong></div>}
                </div>
            }
        </div>
    )
}
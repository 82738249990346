import { ROAD_BLOCKS, ROUGH_PATCH } from "../Conf/conf";

export default function findShortestPath(matrix, useRoughPatches, diagonals, start, end) {
    const numRows = matrix.length;
    const numCols = matrix[0].length;
  
    let dx = [-1, 1, 0, 0];
    let dy = [0, 0, -1, 1];
    if(diagonals){
        dx = [-1, 1, 0, 0, -1, -1, 1, 1];
        dy = [0, 0, -1, 1, -1, 1, -1, 1];
    } 
  
    // Find start and end
    let startRow = -1;
    let startCol = -1;
    let endRow = -1;
    let endCol = -1;
    for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < numCols; j++) {
            if (matrix[i][j] === start) {
                startRow = i;
                startCol = j;
            } else if (matrix[i][j] === end) {
                endRow = i;
                endCol = j;
            }
        }
    }
  
    if (startRow === -1 || startCol === -1 || endRow === -1 || endCol === -1) {
        console.error("Start or end not found.");
        return;
    }
  
    // Queue for BFS
    const queue = [];
    queue.push({ row: startRow, col: startCol, distance: 0 });
  
    // To keep track of visited cells
    const visited = Array(numRows).fill().map(() => Array(numCols).fill(false));
  
    // Parent matrix to store the path
    const parent = Array(numRows).fill().map(() => Array(numCols).fill(null));
  
    visited[startRow][startCol] = true;
  
    // Perform BFS
    while (queue.length > 0) {
        const { row, col, distance } = queue.shift();

        if (row === endRow && col === endCol) {
            // Reconstruct the path and print the indices
            const pathIndices = [];
            let currentRow = row;
            let currentCol = col;
            while (currentRow !== startRow || currentCol !== startCol) {
                pathIndices.unshift({ row: currentRow, col: currentCol });
                const { parentRow, parentCol } = parent[currentRow][currentCol];
                currentRow = parentRow;
                currentCol = parentCol;
            }
            pathIndices.unshift({ row: startRow, col: startCol });
            return pathIndices;
        }
  
        for (let i = 0; i < dx.length; i++) {
            const newRow = row + dx[i];
            const newCol = col + dy[i];

            if (
                newRow >= 0 &&
                newRow < numRows &&
                newCol >= 0 &&
                newCol < numCols &&
                matrix[newRow][newCol] !== ROAD_BLOCKS && (matrix[newRow][newCol] !== ROUGH_PATCH || !useRoughPatches) &&
                !visited[newRow][newCol]
            ) {
                queue.push({ row: newRow, col: newCol, distance: distance + 1 });
                visited[newRow][newCol] = true;
                parent[newRow][newCol] = { parentRow: row, parentCol: col };
            }
        }
    }

    return null;
}
import { TbBarrierBlock } from "react-icons/tb";
import { TbAlertTriangle } from "react-icons/tb";
import { CELL_SIZE } from "../../Conf/conf";

export default function Legend(){
    const getLegend = (name, color, icon, iconClass) => {
        return <div style={{display:'flex', marginBlock:'.1rem'}}>
            <span className={iconClass} style={{borderRadius:'4px',maxWidth:CELL_SIZE, maxHeight:CELL_SIZE,minWidth:CELL_SIZE, minHeight:CELL_SIZE,backgroundColor: color}}>
                <strong className="cell-content">
                    {icon}
                </strong>
            </span>
            <div style={{marginTop:'.7rem', marginLeft:'.2rem'}}>
                {name}
            </div>
        </div>
    }

    return <div style={{display:'flex', marginLeft:'2rem', flexWrap:'wrap', marginBottom:'1rem', gap:'1rem 1rem', fontSize:'.8rem'}}>
        {getLegend("Start (A), End (B), Waypoint, Path", '#a3ff107c',null,"cell-content cell-container")}
        {getLegend("Empty Space", 'gray',null,"cell-content cell-container")}
        {getLegend("Roadblock", 'black', <TbBarrierBlock/>, "cell-icon cell-icon-block cell-content cell-container")}
        {getLegend("Rough Path On", '#474747', <TbAlertTriangle/>, "cell-icon cell-icon-warning-on cell-content cell-container")}
        {getLegend("Rough Path Off", 'gray', <TbAlertTriangle/>, "cell-icon cell-icon-warning-off cell-content cell-container")}
    </div>
}
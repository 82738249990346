import { DROP_ZONE_A, DROP_ZONE_B, EMPTY_SPACE, ROAD_BLOCKS, ROUGH_PATCH } from "../Conf/conf";
import { checkIfOnPath } from "./path";

export const getBackgroundColor = (type, row, col, useRoughPatches, path) => {
    if(typeof type === 'number') return '#a3ff107c';
    if(type === DROP_ZONE_A) return '#a3ff107c';
    if(type === DROP_ZONE_B) return '#a3ff107c';
    if(checkIfOnPath(row, col, path)) return '#a3ff1048';
    if(type === EMPTY_SPACE) return 'gray';
    if(type === ROAD_BLOCKS) return 'black';
    if(type === ROUGH_PATCH) {
        if(useRoughPatches){
            return '#474747';
        } else {
            return 'gray';
        }
    }
    return 'black';
}

export const getColor = (type) => {
    if(typeof type === 'number') return 'black';
    if(type === DROP_ZONE_A) return 'black';
    if(type === DROP_ZONE_B) return 'black';
    return 'white';
}
import { CELL_SIZE_NUMBER } from "../../Conf/conf";

export default function drawMatrix(matrix, path) {
    if(path !== null){
        const canvas = document.getElementById("matrix-canvas");
    
        if (!canvas) {
            return;
        }
      
        const ctx = canvas.getContext("2d");
      
        canvas.width = matrix[0].length*(CELL_SIZE_NUMBER+4);
        canvas.height = matrix.length*(CELL_SIZE_NUMBER+4);
    
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
      
        const cellSize = CELL_SIZE_NUMBER;
        const padding = 4;
      
        // Draw the line connecting the path
        if (path.length > 1) {
          ctx.strokeStyle = "#0674eaee";
          ctx.lineWidth = 3;
      
          ctx.beginPath();
          const startPoint = path[0];
          ctx.moveTo(startPoint.col * (cellSize + padding) + cellSize / 2, startPoint.row * (cellSize + padding) + cellSize / 2);
      
          for (let i = 1; i < path.length; i++) {
              const point = path[i];
              ctx.lineTo(point.col * (cellSize + padding) + cellSize / 2, point.row * (cellSize + padding) + cellSize / 2);
          }
      
          ctx.stroke();
        }
    } else {
        const canvas = document.getElementById("matrix-canvas");
    
        if (!canvas) {
            return;
        }
        const ctx = canvas.getContext("2d");
        canvas.width = matrix[0].length*(CELL_SIZE_NUMBER+4);
        canvas.height = matrix.length*(CELL_SIZE_NUMBER+4);
    
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
}
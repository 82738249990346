export const createMatrix = (N,M,char) => {
    const matrix = [];
    for (let i = 0; i < N; i++) {
        const row = [];
        for (let j = 0; j < M; j++) {
            row.push(char);
        }
        matrix.push(row);
    }
    return matrix;
}

const placeCharInRandomPos = (char, oldMatrix, empty) => {
    const emptySpaces = [];
    for (let i = 0; i < oldMatrix.length; i++) {
        for (let j = 0; j < oldMatrix[i].length; j++) {
            if (oldMatrix[i][j] === empty) {
                emptySpaces.push([i, j]);
            }
        }
    }

    if (emptySpaces.length === 0) {
        console.error("No empty spaces");
        return oldMatrix;
    }

    const randomIndex = Math.floor(Math.random() * emptySpaces.length);
    const [row, col] = emptySpaces[randomIndex];

    const newMatrix = [...oldMatrix];
    newMatrix[row][col] = char;
    return newMatrix;
}

export const generateMatrix = (n, m, start, end, empty, blocks, roughs, x, y) => {
    let newMatrix;
    newMatrix = placeCharInRandomPos(start, createMatrix(n, m, empty), empty);
    newMatrix = placeCharInRandomPos(end, newMatrix, empty);

    for(let i=0; i<x; i++){
        newMatrix = placeCharInRandomPos(blocks, newMatrix, empty);
    }

    for(let i=0; i<y; i++){
        newMatrix = placeCharInRandomPos(roughs, newMatrix, empty);
    }
    
    return newMatrix;
}

export const findElementPosition = (matrix, target) => {
    for (let row = 0; row < matrix.length; row++) {
        for (let col = 0; col < matrix[row].length; col++) {
            if (matrix[row][col] === target) {
                return { row, col };
            }
        }
    }
    return null;
}